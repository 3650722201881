<template>
  <div>
    <esmp-select
      :placeholder="placeholder"
      filterable
      :remote-method="waitUserPrint"
      :loading="loading"
      :show-search-results="showSearchResults"
      v-model="val"
      @on-query-change="(v) => query = v"
      @on-select="selected"
    >
      <esmp-select-option
        v-for="r in dropDownResults"
        :key="`${r.assignmentId}_${r.tabNumber}`"
        :value="`${r.assignmentId}_${r.tabNumber}`"
        :label="r.fullName"
      >
        <span class="user-name" v-html="r.fullName" />

        <span class="user-position">
          {{ r.appointmentName }} {{ r.company }}
        </span>
        <span class="user-email">
          {{ r.email }}
        </span>

        <span class="user-fired" v-if="r.fired">уволен</span>
      </esmp-select-option>
    </esmp-select>
    <div v-if="errorMessage" class="business-trip__error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const { CancelToken } = axios;
let cancel;

const SEARCH_TIMEOUT_DELAY = 100;

export default {
  name: 'UserSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Поиск сотрудника',
    },
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    searchUrl: {
      type: String,
      default: '/api/business-trip/user-search',
    },
    value: {
      type: Object,
      default: () => null,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      val: '',
      dropDownResults: [],
      loading: false,
      query: '',
      searchTimeoutId: null,
      showSearchResults: true,
    };
  },
  methods: {
    async search() {
      if (cancel) cancel();
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query.trim().length >= 3) {
        this.loading = true;

        // eslint-disable-next-line prefer-object-spread
        const searchParams = Object.assign({}, this.searchParams, {
          query: this.query,
        });

        await axios
          .get(this.searchUrl, {
            params: searchParams,
            cancelToken: new CancelToken((c) => {
              cancel = c;
            }),
          })
          .then((response) => {
            this.loading = false;
            this.dropDownResults = response.data;
          })
          .catch((err) => {
            if (axios.isCancel(err)) return;
            // eslint-disable-next-line consistent-return
            return err;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    waitUserPrint(query) {
      if (this.value && query === this.value.fullName) return;
      if (this.value && query === `${this.value.assignmentId}_${this.value.tabNumber}`) return;
      if (cancel) cancel();
      if (this.value) {
        this.$emit('input', null);
      }

      this.dropDownResults = [];

      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(this.search, SEARCH_TIMEOUT_DELAY);
    },
    selected(user) {
      const userValue = user
        ? this.dropDownResults.find((r) => `${r.assignmentId}_${r.tabNumber}` === user.value)
        : [];
      this.$emit('input', userValue);
      this.val = userValue.tabNumber;
    },
  },
  watch: {
    value(val) {
      if (val && this.query !== val.fullName) {
        this.dropDownResults = [val];
        this.val = `${val.assignmentId}_${val.tabNumber}`;
      }
    },
  },
  created() {
    if (this.value) {
      this.dropDownResults = [this.value];
      this.val = `${this.value.assignmentId}_${this.value.tabNumber}`;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .esmp-select-item {
  display: flex;
  flex-wrap: wrap;
}
.user-name {
  margin-right: 6px;
}
.user-position {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-primary-1-day;
  white-space: normal;
}
.user-email {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-black-op-40;
}
.user-fired {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $color-red;
}
</style>
